import React, { useState, useEffect, useRef } from "react";
import { FaCalendarAlt } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import SearchForm from "../../components/searchForm";
import { useDispatch, useSelector } from "react-redux";
import { Image } from "react-bootstrap";
import defaultThumbnail from "../../assets/Images/default_image.png";
import FormattedDate from "../../components/formattedDate";
import EmptyData from "../../components/royalActivities/emptyData";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "../../components/InfiniteScroll";
import {
  fetchDataSearchPost,
  setCurrentSearchPostPage,
} from "../../redux/action";

export default function SearchResults(props) {
  const [contentHeight, setContentHeight] = useState(0);
  const headerHeight = 179;
  const footerHeight = 45;

  const calculateContentHeight = () => {
    const windowHeight = window.innerHeight;
    const availableHeight = windowHeight - (headerHeight + footerHeight);
    setContentHeight(availableHeight);
  };

  useEffect(() => {
    calculateContentHeight(); // Initial calculation
    const handleResize = () => {
      calculateContentHeight();
    };
    window.addEventListener("scroll", handleResize);
    return () => {
      window.removeEventListener("scroll", handleResize);
    };
  }, []); // Empty dependency array ensures that effect runs only on mount and unmount

  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const { data, isLoading, error, currentPage } = useSelector((state) => state.searchPosts || {});
  const { filters } = useSelector((state) => state);
  const { languageCode } = useSelector((state) => state.language);
  const searchParams = new URLSearchParams(location.search);
  const searchData = searchParams.get("name");
  const searchCategory = searchParams.get("category");
  const containerRef = useRef();
  const dataRef = useRef(data);

  useEffect(() => {
    dataRef.current = data; // Update the ref when data changes
  }, [data]);

  const handleScroll = () => {
    const container = containerRef.current;
    if (!container || !dataRef.current) {
      return;
    }
    const bottom =
      container.scrollTop + container.clientHeight >=
      container.scrollHeight - 1;

    if (
      bottom &&
      !isLoading &&
      dataRef.current?.length >= currentPage * 12
    ) {
      const nextPage = currentPage + 1;
      dispatch(setCurrentSearchPostPage(nextPage));
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (!container) {
      return;
    }

    container.addEventListener("scroll", handleScroll);
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [containerRef, handleScroll]);

  useEffect(() => {
    dispatch(
      fetchDataSearchPost(
        languageCode,
        currentPage,
        filters.name,
        filters.start_date,
        filters.end_date,
        filters.tag_id,
        "",
        searchCategory
      )).then((response) => {
        if (response.data.results.length == 0) {
          const container = containerRef.current;
          container.removeEventListener("scroll", handleScroll);
        } else {
          // Scroll the container back to the top
          if (currentPage === 1) {
            const container = containerRef.current;
            container.scrollTop = 0;
          }
        }
      })
      .catch((error) => {
        console.error("Error in fetching data:", error.message);
      });
  }, [
    currentPage,
    languageCode, 
    filters.end_date, 
    filters.name, 
    filters.start_date, 
    filters.tag_id
  ]);

  const renderData = () => {
    const highlightTitleName = (text, query) => {
      if (!text || !query) return text;

      // Create a regular expression to match the query globally and case insensitively
      const regex = new RegExp(`(${query})`, "gi");

      // Highlight the matched substring by wrapping it with HTML markup
      return text.replace(
        regex,
        (match) =>
          `<mark style="font-size: 14px;background-color: #032ea1;color: #fff;padding: 4px 5px;border-radius: 5px;margin: 0;">${match}</mark>`
      );
    };
    return (
      <ul className="row d-lg-flex list-unstyled image-block px-lg-0 mx-lg-0">
        {data.length > 0
          ? data.map((list, index) => {
            return (
              <React.Fragment key={index}>
                {list.hasOwnProperty("pdf_file") ? (
                  <a
                    href={list.pdf_file === null ? "#" : `${list.pdf_file}`}
                    target={list.pdf_file === null ? "_self" : "_blank"}
                    rel="noopener noreferrer"
                    style={{ paddingLeft: 0 }}
                  >
                    <div className="blog-search">
                      <div style={{ display: "flex" }}>
                        {list.thumbnail == null ? (
                          <div className="square">
                            <Image
                              src={defaultThumbnail}
                              className="img-responsive w-100 thumbnail"
                            />
                          </div>
                        ) : (
                          <div className="square">
                            <Image
                              src={list.thumbnail}
                              className="img-responsive w-100 thumbnail"
                            />
                          </div>
                        )}

                        <div className="content-name post-search-result">
                          <h5
                            className="post-name"
                            dangerouslySetInnerHTML={{
                              __html: highlightTitleName(
                                list.name,
                                searchData
                              ),
                            }}
                          />
                          <p
                            className="post-excerpt"
                            dangerouslySetInnerHTML={{
                              __html: highlightTitleName(
                                list.excerpt,
                                searchData
                              ),
                            }}
                          />
                          {list.category !== null ? (
                            <div className="content-list-category-name">
                              <p>Category :</p>
                              <p className="category-name">
                                {list.category.name}
                              </p>
                            </div>
                          ) : null}{" "}
                        </div>
                      </div>
                      <div className="content-date">
                        <span>
                          <FaCalendarAlt color="#032ea1" size={11} />
                          <FormattedDate date={list.date} />
                        </span>
                      </div>
                    </div>
                  </a>
                ) : (
                  <Link
                    key={list.id}
                    to={`./articles/${list.id}`}
                    // to={!list.hasOwnProperty('pdf_file') ? `${list.pdf_file}` : `/articles/${list.id}`}
                    style={{ paddingLeft: 0 }}
                  >
                    <div className="blog-search">
                      <div style={{ display: "flex" }}>
                        {list.thumbnail == null ? (
                          <div className="square">
                            <Image
                              src={defaultThumbnail}
                              className="img-responsive w-100 thumbnail"
                            />
                          </div>
                        ) : (
                          <div className="square">
                            <Image
                              src={list.thumbnail}
                              className="img-responsive w-100 thumbnail"
                            />
                          </div>
                        )}

                        <div className="content-name post-search-result">
                          <h5
                            className="post-name"
                            dangerouslySetInnerHTML={{
                              __html: highlightTitleName(
                                list.name,
                                searchData
                              ),
                            }}
                          />
                          <p
                            className="post-excerpt"
                            dangerouslySetInnerHTML={{
                              __html: highlightTitleName(
                                list.excerpt,
                                searchData
                              ),
                            }}
                          />
                          {list.category !== null ? (
                            <div className="content-list-category-name">
                              <p>Category :</p>
                              <p className="category-name">
                                {list.category.name}
                              </p>
                            </div>
                          ) : null}{" "}
                        </div>
                      </div>
                      <div className="content-date">
                        <span>
                          <FaCalendarAlt color="#032ea1" size={11} />
                          <FormattedDate date={list.date} />
                        </span>
                      </div>
                    </div>
                  </Link>
                )}
              </React.Fragment>
            );
          })
          : data.length === 0 ? <EmptyData /> : error === "404" && <EmptyData />}
      </ul>
    );
  };

  const count = data.filter((item) => item.is_deleted === false).length;
  return (
    <div className="main-wrapper d-lg-flex list-unstyled image-block px-lg-0 mx-lg-0" style={{ paddingBottom: 0 }}>
      <div className="sidebar-container"
        style={{
          minHeight: contentHeight + "px",
        }}>
        <div className="main-sidebar result-search-sidebar">
          <SearchForm searchData={searchData} searchCategory={searchCategory} />
        </div>
      </div>
      <div className="page-container">
        <div className="main">
          <div className="group-content-post">
            <h4 className="title-results">
              {t("result_found")} {`( ${count} )`}
            </h4>

            <InfiniteScroll
              renderData={renderData}
              isLoading={isLoading}
              fetchMoreData={data}
              currentPage={currentPage}
              error={error}
              containerRef={containerRef}
              countCols={3}
              valueMargin={10}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
