import React from "react";
import { Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { AiFillTwitterCircle } from "react-icons/ai";
import { SiFacebook, SiTelegram } from "react-icons/si";
import {
  TwitterShareButton,
  FacebookShareButton,
  TelegramShareButton,
  LinkedinShareButton,
} from "react-share";

const SocialShare = ({ url, title, description, thumbnail }) => {
  
  const { t } = useTranslation();
  const twitterTitle = `${title}:${description}`;

  const handleTelegramShare = () => {
    const encodedTitle = encodeURIComponent(title);
    // const encodedThumbnail = encodeURIComponent(thumbnail); // Replace with your actual thumbnail URL
    const telegramUrl = `https://t.me/share/url?url=${url}&text=${encodedTitle}`;
    window.open(telegramUrl, '_blank');
  };


  return (
    <div className="d-lg-flex socail-share align-items-center">
      <h2>{t('share')}</h2>
      <Nav as="ul">

        <Nav.Item as="li">
          <Nav.Link >
            <TelegramShareButton url={url} description={description} title={title} imageURL={thumbnail}>
              <SiTelegram size={25} color="#032EA1" />
            </TelegramShareButton>

          </Nav.Link>
        </Nav.Item>

        <Nav.Item as="li">
          <FacebookShareButton url={url} windowWidth="800" windowHeight="500" quote={title} image={thumbnail} imageURL={thumbnail}>
            <SiFacebook size={25} color="#032EA1" />
          </FacebookShareButton>
        </Nav.Item>

        <Nav.Item as="li">
          <TwitterShareButton style={{ marginTop: -2 }}
            url={url}
            title={twitterTitle}
            via="yourTwitterHandle"
            image={thumbnail}>
            <AiFillTwitterCircle size={29} color="#032EA1" />
          </TwitterShareButton>
        </Nav.Item>

      </Nav>
    </div>
  );
};

export default SocialShare;
